<template>
  <v-sheet
    color="white"
    height="50"
    class="d-flex align-center pa-2"
  >
    <div
      :class="titleclass"
      :style="`color: ${titlecolor}`"
    >
      {{ title }}
    </div>
    <v-spacer />
    <v-breadcrumbs
      :items="breadcrumbs"
      large
    />
  </v-sheet>
</template>
<script>
  export default {
    name: 'BreadcrumbBar',
    props: {
      title: {
        type: String,
        required: true,
      },
      breadcrumbs: {
        type: Array,
        required: true,
      },
      titleclass: {
        type: String,
        default: 'display-2 font-weight-bold',
      },
      titlecolor: {
        type: String,
        default: '#00867c',
      },
    },
  }
</script>
